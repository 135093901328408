import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { VehicleComponent } from './vehicle/vehicle.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./test/test.module').then((x) => x.TestModule)
      },
      {
        path: 'test',
        loadChildren: () => import('./test/test.module').then((x) => x.TestModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./tenant/tenant.module').then((x) => x.TenantModule)
      },
      {
        path: 'tenant',
        loadChildren: () => import('./tenant/tenant.module').then((x) => x.TenantModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./company/company.module').then((x) => x.CompanyModule)
      },
      {
        path: 'company',
        loadChildren: () => import('./company/company.module').then((x) => x.CompanyModule)
      },
    ]
  }, 
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./finance/finance.module').then((x) => x.FinanceModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.module').then((x) => x.FinanceModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./customers/customers.module').then((x) => x.CustomersModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then((x) => x.CustomersModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./users/users.module').then((x) => x.UsersModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((x) => x.UsersModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./branches/branches.module').then((x) => x.BranchesModule)
      },
      {
        path: 'branches',
        loadChildren: () => import('./branches/branches.module').then((x) => x.BranchesModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./agent/agent.module').then((x) => x.AgentModule)
      },
      {
        path: 'agent',
        loadChildren: () => import('./agent/agent.module').then((x) => x.AgentModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./vehicle/vehicle.module').then((x) => x.VehicleModule)
      },
      {
        path: 'vehicle',
        loadChildren: () => import('./vehicle/vehicle.module').then((x) => x.VehicleModule)
      },
     
    ]
  }
  ,
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./changepassword/changepassword.module').then((x) => x.ChangepasswordModule)
      },
      {
        path: 'changepassword',
        loadChildren: () => import('./changepassword/changepassword.module').then((x) => x.ChangepasswordModule)
      },
     
    ]
  },
  {
  path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./reports/reports.module').then((x) => x.ReportsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((x) => x.ReportsModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./documenttypes/documenttypes.module').then((x) => x.DocumenttypesModule)
      },
      {
        path: 'document',
        loadChildren: () => import('./documenttypes/documenttypes.module').then((x) => x.DocumenttypesModule)
      },
     
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./expendituretypes/expendituretypes.module').then((x) => x.ExpendituretypesModule)
      },
      {
        path: 'expenditure',
        loadChildren: () => import('./expendituretypes/expendituretypes.module').then((x) => x.ExpendituretypesModule)
      },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }

