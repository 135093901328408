import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CountToModule } from 'angular-count-to';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { LightboxModule } from 'ngx-lightbox';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';


import { PagesRoutingModule } from './pages-routing.module';


import { PagesComponent } from './pages.component';

import {AvatarModule} from 'primeng/avatar';
import { FooterComponent } from './layouts/footer/footer.component';
import {DropdownModule} from 'primeng/dropdown';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SkeletonModule } from 'primeng/skeleton';
import { ReportsComponent } from './reports/reports.component';
@NgModule({
  declarations: [
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    PagesComponent
  ],
  imports: [
    CommonModule,
    CountToModule,
    SharedModule,
    NgApexchartsModule,
    PagesRoutingModule,
    ReactiveFormsModule,
    SimplebarAngularModule,
    DropdownModule,
    FormsModule,
    AvatarModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    LightboxModule,
    LeafletModule,
    InputSwitchModule,
    SkeletonModule
  ]
})
export class PagesModule { }
